/* welcom css empty */
body.dark_body .welcome_out{
  /* color:black; */
  background: #0D1224;
}

body.dark_body .welcome_out .info{
  color:black;
}

.welcome .welcom_box{
  background-position: bottom;
  background-size: 50%;
  background-repeat: no-repeat;
}

.pay_glow { 
  box-shadow: #FFF 0 -1px 4px, #ff0 0 -2px 10px, #ffee8f 0 -10px 20px, rgb(255, 214, 32) 0 -4px 5px, 5px 5px 10px 5px rgba(0,0,0,0) !important;
}