.side_list_player a {
  /* background: white; */
  color: white !important;
}


.choose_season {
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  width: 100%;
  height: 90px;
  z-index: 999;
  transform: translate(0, -80%);
  transition: 1000ms;
}

.choose_season:hover {
  transform: translate(0, 0);

}

.choose_season button {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-size: 1.25em;
  font-weight: 700;
  border: none;
  box-shadow: #FFF 0 -1px 4px, #ff0 0 -2px 10px, #ff8000 0 -10px 20px, red 0 -18px 40px, 5px 5px 15px 5px rgba(0, 0, 0, 0);
  margin: 0 8px;

}

.movies_list {
  /* border-left: 1px solid black; */
  height: 90vh;
  overflow-y: scroll;

}

.movies_list .subject_bar {
  background: #f3f3f3;
  box-shadow: 0 5px 5px -7px #000, 5px 5px 5px 5px transparent;
  position: relative;
  z-index: 99;
}


body.dark_body .movies_list .subject_bar {
  /* background: rgb(243, 243, 243); */
  background: rgb(46, 47, 69);
  position: relative;
  z-index: 99;
  box-shadow: 0px 5px 5px -7px #000000, 5px 5px 5px 5px rgba(0, 0, 0, 0);
}


.movies_list .video_bar {
  transition: 400ms;
  cursor: pointer;
  color:black !important;
}


body.dark_body .movies_list .video_bar {
  transition: 400ms;
  cursor: pointer;
  color:whitesmoke !important;
}

.movies_list .video_bar:hover {
  /* background: rgb(214, 214, 214); */
  background: silver;
}

body.dark_body .movies_list .video_bar:hover {
  /* background: rgb(214, 214, 214); */
  background: rgb(96, 58, 114)
}

.movies_list .video_bar:active {
  background: rgb(138, 138, 138);
}
/* 2024 */
.movies_list .video_bar.video_play{
  color:white !important;
}

.player_div {
  min-height: 90vh;

}



.player_div .player_inside {
  background: black;
  min-height: 70vh;
  position: relative;
}

.player_div_expand .player_inside {
  min-height: 85vh;
}

/* אייקון מינימייז */
.player_div .expan_min_icon {
  position: absolute;
  z-index: 99;
  top: 53vh;
}


@media (max-width:768px) {

  .player_div {
    min-height: 20px;
  }

  .player_div .player_inside {
    background: black;
    min-height: 36%;
  }

  .player_div h1 {
    font-size: 1.3em;
    margin: 0;
    text-align: center;
  }

  .movies_list {
    height: 40vh;
  }


}

@media (max-width:400px) {
  .player_div .player_inside {
    min-height: 28%;
  }
}






/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: rgb(80, 0, 200) #0D1224;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  background: #0D1224;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(80, 0, 200);
  border-radius: 10px;
  border: 3px solid #0D1224;
}