.hidden {
  display: none !important;
}

.row{
  min-height: inherit;
}

main{
  min-height: 84vh;
}

.top-border-home{
  border-top:1px solid rgb(59, 28, 108);
  min-height: 200px;
  background-attachment: scroll;
  background-position: center top;
  background-repeat: no-repeat;
  /* background-size: cover; */
}


a.display-6[href="/courses_vod/"]{
  padding:12px 24px;
}
/* כפתור לרשימת הקורסים בעמוד הבית */
a.display-6[href="/courses_vod/"]:hover{
  background: rgb(196, 26, 222) !important;
}






.strip{
  min-height: 500px;
  background: black;
  color:white;
  background-position: center bottom;
  background-size: cover;
  /* background-attachment: fixed; */
}

.strip h1{

  font-weight: 600;

}

.strip .btn-start{
  background: linear-gradient(to right, rgba(255,84,235,1) 0%, rgb(79, 4, 150) 100%);
  border: none;
  border-radius: 16px;
  box-shadow: 1px 10px 16px -7px rgba(0,0,0,0.75);
  transition: 400ms;
  font-size: 20px;
}

.strip .btn-start:hover{
  transform: scale(0.9)
}

.strip .my-card{
  box-shadow: 0px 0px 13px 3px rgba(0,0,0,0.55) !important;
}

.strip .my-card .bg-card{
  min-height: 300px;
  background-color:black;
  background-size:cover;
  background-position: left center;

}

.box_benefit{
  border:1px solid rgb(96, 96, 139);
  box-shadow: 0px 0px 13px 3px rgba(0,0,0,0.55) !important;
  border-radius: 8px ;
}

.courses{
  min-height: 200px;
  padding-bottom: 48px;
}

.courses .box_course{
  text-align: center;
  border-radius: 8px;
  padding-bottom: 30px;
  /* background:rgb(67, 17, 17); */
  box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
  border:1px solid rgb(83, 44, 99);
  transition: 400ms;
  background: #10162A;
  position: relative;
  min-height: 330px;
}

.courses .box_course:hover{
  border:1px solid gold;
  transform: scale(1.02);

}

.courses .box_course .box_pic{
  min-height: 210px;
  background-size: cover;
  background-position:right top;
  background-color:rgb(0, 0, 0);
  border-radius: 8px 8px 0 0;
}

.courses .box_course .box_pic img{
  border-radius: 16px 16px 0 0;
}

.courses .box_course .box_course_list{
  min-height: 160px;
  background-position:right center;
  transition: 800ms;
}

.box_btns_wrap{
  position: absolute;
  width: 100%;
  bottom: 16px;
}

.courses .box_course a, .courses .box_course span{
  color:white; 
  text-decoration: none;
  border:1px solid rgb(83, 44, 99);
  padding:8px 16px;
  border-radius: 8px;
  transition: 700ms;
  display:inline-block;
  background: #182758;

  /* transform: scale(1.3,1.3) !important; */
}

.courses .box_course a:hover, .courses .box_course span:hover{
  transform: scale(0.95);
  border:1px solid gold;
 
}

.courses .box_course h3{
  margin:24px 0;
}

.features img{
  border:1px solid rgb(96, 96, 139);
  box-shadow: 0px 0px 13px 3px rgba(0,0,0,0.55) !important;
  border-radius: 8px ;
}

.welcome_out{
  background-color:#ddd;

}

.welcome_out .welcome{
  background-position: right bottom;
  background-size: 24% auto   ;
  background-repeat: no-repeat;
}

.welcome_out .welcome h1 {
  color: #FFFFFF;
  font-size: 3em;
  
/* background: #0e8dbc; */
text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15);
}

.welcome_out .welcome ul{
  margin:0;
}

.welcome_out .welcome ul li{
  font-size: 1.4em;
  margin-bottom: 0.6em;
}

.welcome_out .welcome button{
  width: 48%;
  border:2px solid white;
}

.welcome_out .new_course_item{
  background: #b9b9b9;
  transition: 1000ms;
}

.welcome_out .new_course_item:hover{
  background: rgb(197, 170, 247) !important;
}

.pricing{
  background:  purple;
  border-bottom: 1px solid white;
  /* background: linear-gradient(45deg, rgb(234, 234, 234) 2%, rgb(125, 125, 125) 54%, rgb(73, 73, 73) 98%); */
  background: linear-gradient(45deg, rgb(255, 60, 255) 2%, rgb(109, 23, 126) 98%);


}

.price_list .box{
  background: white;
  border-radius: 8px;
  box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
  min-height: 500px;
}

.pricing a{
  color:white !important;
}

body.dark_body .price_list .box{
  color:black;
}

.price_list ul{
  margin-top:32px !important;
}

.price_list .btn{
  font-size: 1.5em;
  width: 90%;
  /* border:2px solid black; */
  box-shadow: -10px 0px 13px -7px #999999, 10px 0px 13px -7px #838383, 5px 5px 15px 5px rgba(0,0,0,0);
}


@media (max-width:768px){

  .welcome_out .welcome h1 {
    font-size: 2em;
  }
  .welcome_out .welcome ul li{
    font-size: 1em;
    margin-bottom: 0.5em;
  }


  .strip .my-card .bg-card{
    min-height: 200px;  
  }

  .courses .box_course{
    width:95%;
    max-width: 400px;
    margin:0 auto;
    margin-bottom: 16px;
  }

  .courses .box_course .box_pic{
    min-height: 170px;
    background-position: center ;
  }

  .strip{
    background-attachment: local;
  }
}