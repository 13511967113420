.img_load{
  animation: logo22 5s linear 0s 99 normal forwards ;
  /* transition: 1000ms; */
  /* display: block; */
  transform: rotate(0deg);
}

@keyframes logo22 {
  0%  {transform: rotate(0);}
  50% {transform: rotate(270deg);}
  100%  {transform: rotate(720deg);}
}