body{
  direction: rtl;
}

/* דורש תיקון ברשימת סרטונים  */
body.dark_body{
  background: #0D1224;
  background: radial-gradient(ellipse at center, rgba(33,30,70,1) 0%, rgba(13,18,36,1) 100%);
  color:#ddd;
}

body.dark_body a{
  color:#ccc ;
}

body.dark_body .btn a{
  color:black !important;
}

.container{
  min-height: inherit;
  max-width: 1200px;
}

.row{
  min-height: inherit;
}

header{
  min-height: 70px;
  position: relative;
  z-index: 999;
  /* background: black; */
}

header .container{
  max-width: 1600px;
}

header .logo{
  max-width: 270px;
  transition: 1000ms;
}

header .logo:hover{
  transform: scale(0.9);
}

header .burger{
  display: none;
}

header span.user_circle{
  width:44px;
  height: 44px;
  background: grey;
  color:white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: bold;
  margin-left: 16px;
}

header nav a{
  color:black;
  font-weight: bold;
  text-decoration: none;
  padding:0 12px;
  transition: 1000ms;
}

header nav a:hover{
  color:rgb(198, 192, 255);
  /* font-weight: bold;
  text-decoration: none;
  padding:0 12px; */
}


/* footer */
footer{
  min-height: 70px;
  background:#5000C8;
  color:white;
}

footer .row .nav_footer a{
  color:white;
  text-decoration: none;
  padding:0 8px;  
  border-left:1px solid white;
}

footer .row .nav_footer a:hover{
  color:skyblue;
}

footer .row .social_icons a{
  color:white;
  font-size: 2.5em;
  padding:12px;
}

footer .row .social_icons a:hover{
  color:skyblue;
}

/* נגיש לי */
#NagishLiTrigger{
  top:80px !important;
  right: auto !important;
  left:10px !important;
  transform: scale(0.8,0.8);
}

@media (max-width:1199px){
  header .logo {
    width: 90%;
  }

  header .my-nav{
    position: absolute;
    top:70px;
    right:4px;
    background: white;
    width:70%;
    display: none;
  }

  body.dark_body header .my-nav{
    background: #0D1224;
  }

  header .burger{
    display: block;
  }

  header .my-nav .user_circle{
    /* display: none; */

  }

  header .my-nav a{
    display: block;
    padding:16px;
  }

  header .my-nav a:hover{
    display: block;
    padding:16px;
    background:silver;
  }

  header .user_circle{
    position: absolute;
    left:0;
    top:8px;
  }
  
  footer .row .nav_footer a{
    display: block;
    border-left:none;
    padding:8px;
    font-size: 1.2em;
  }

  #NagishLiTrigger{
    display: none !important;
  }
}