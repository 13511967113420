.persom{
  position: fixed;
  width: 100%;
  height: 100%;
  top:0;
  left: 0;
  background: rgba(3, 3, 3, 0.73);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.persom .box_persom{
  width: 100%;
  max-width: 650px;
  min-height: 340px;
  /* background: white; */
  background: #212752;
  border:2px solid purple;
  border-radius: 8px;
  position: relative;
}

.persom .box_persom article{
  min-height: 350px;
}

.persom .box_persom ul{
  background: rgba(0, 0, 0, 0.7);
  width: max-content;
  padding: 8px 32px;
  color:white; 
}

.persom .box_persom .btns-persom{
  background: rgba(0, 0, 0, 0.7);
  padding: 8px;
  position: absolute;
  bottom: 12px;
  width: 100%;
  text-align: center;
  /* left:-17%; */
  right: auto;
  /* border:2px solid black; */
}

.persom .box_persom .btns-persom button{
  font-size: 18px !important;
} 